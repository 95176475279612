.o-language-list {
  ul {
    display: flex;

    & > *:not(:first-child)::before {
      content: '/';
      margin: 0 0.5em;
    }
  }

  li {
    text-transform: capitalize;

    @include media-breakpoint-down(sm) {
      font-size: var(--step--1);
    }
  }
}
