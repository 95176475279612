.c-home-banner__wrapper {
  position: relative;
}

.c-home-banner__media {
  @include media-breakpoint-up(sm) {
    --column-span: 10;
    --column-start: 2;

    padding: calc(var(--step-6) * 0.6) 0 calc(var(--step-6) * 0.4);
  }
}

.c-home-banner__figure {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  border-radius: var(--border-radius);

  @include media-breakpoint-up(sm) {
    padding-bottom: min(65vh, calc(8 / 16 * 100%));
  }
}

.c-home-banner__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-home-banner__title {
  font-size: var(--step-6);
  font-weight: 600;

  @include media-breakpoint-up(sm) {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    line-height: 1;
    text-align: center;
  }
}

.c-home-banner__content {
  @include media-breakpoint-up(sm) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.c-home-banner__cta {
  @include media-breakpoint-up(sm) {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 0;
    transform: translate(-50%, -50%);
  }
}
