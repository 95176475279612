.c-page-header-collection {
  padding-bottom: var(--space-l);
  background: var(--color-theme);

  & + * {
    --flow: var(--space-2xl);
  }
}

.c-page-header-collection__title {
  --measure: 24ch;

  margin-top: var(--space-6xl);
  color: rgb(255 255 255 / 50%);
  font-size: var(--step-6);
}

.c-page-header-collection__title-inner {
  margin-right: 0.25em;
  color: var(--color-white);
  opacity: 1;

  @include media-breakpoint-down(md) {
    display: block;
    margin-right: 0;
  }
}
