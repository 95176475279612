.c-newsletter {
  padding-top: var(--space-2xl);
  padding-bottom: var(--space-2xl);
  background: var(--color-gray-15);

  & + .c-site-footer {
    --flow: 0;

    border-top: 1px solid var(--color-gray-30);
  }
}

.c-newsletter__title {
  font-size: var(--step-3);

  @include media-breakpoint-up(sm) {
    --column-span: 5;
  }
}

.c-newsletter__form {
  @include media-breakpoint-up(sm) {
    --column-span: 6;
    --column-start: 7;
  }

  input,
  textarea  {
    background-color: var(--color-gray-15) !important;
  }
}
