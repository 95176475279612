.c-video {

}

.c-video__container {
  position: relative;
  display: block;
  overflow: hidden;
  padding-bottom: calc(2.5 / 4) * 100%;
  background: var(--color-placeholder);
  border-radius: var(--border-radius);
  will-change: transform; // For border radius on mobile

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(0 0 0 / 30%);
    content: '';
  }
}

.c-video__ui {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: var(--color-white);

  .o-icon--play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10vw;
    height: 10vw;
    stroke-width: 1.35px;

    @include media-breakpoint-up(sm) {
      stroke-width: 1px;
      width: 5vw;
      height: 5vw;
    }
  }

  .o-icon--circle {
    position: absolute;
    top: 50%;
    left: 49%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 30vw;
    stroke-width: 0.5px;

    @include media-breakpoint-up(sm) {
      left: 49.5%;
      stroke-width: 0.35px;
      width: 15vw;
      height: 15vw;
    }
  }
}

.c-video__image {
  @include image-bg;

  .video-loaded & {
    opacity: 0;
  }
}
