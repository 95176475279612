.c-posts {
  padding-bottom: var(--space-section);

  & + .c-site-footer {
    --flow: 0;
  }
}

.c-posts__filters {
  display: flex;
  flex-flow: wrap;
  margin-top: var(--space-m);
  font-size: var(--step--2);
  gap: var(--space-2xs-xs);

  @include media-breakpoint-up(sm) {
    margin-top: var(--space-3xl);
  }
}

.c-posts__filter {
  padding: var(--space-3xs) var(--space-xs);
  padding-top: 0.5em;
  text-decoration: none;
  border: 1px solid var(--color-white);
  border-radius: 100px;

  &.is-active {
    background-color: var(--color-mariner);
    border-color: var(--color-mariner);
  }
}
