.c-text-post {
  --gutter-y: var(--space-2xl);

  .c-images + &,
  .c-banner + & {
    --flow: var(--space-2xl);
  }
}

.c-text-post__col  {
  @include media-breakpoint-up(sm) {
    --column-span: 10;
    --column-start: 2;
  }

  @include media-breakpoint-up(md) {
    --column-start: 3;
    --column-span: 8;
  }
}
