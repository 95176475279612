.c-product-item {
  text-decoration: none;
}

.c-product-item__title {
  margin-top: var(--space-m);
  margin-left: calc(var(--space-2xs) - 0.5em);
  font-size: var(--step--1);
  font-weight: 700;
  line-height: 1.35;
}

.c-product-item__labels {
  display: inline;

  .can-hover & {
    background-image: linear-gradient(currentcolor, currentcolor);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0 2px;
    transition: background-size 0.75s cubic-bezier('easeOutQuint');
  }

  .can-hover a:hover & {
    background-position: 0% 100%;
    background-size: 100% 2px;
  }

  span {
    display: inline-block;

    &::before {
      position: relative;
      top: 0.5em;
      display: inline-block;
      width: 0.5em;
      height: 1em;
      background: var(--color-gray-90);
      content: '';
    }
  }
}

.c-product-item__subtitle {
  margin-top: var(--space-3xs);
  margin-left: var(--space-2xs);
  font-size: var(--step--1);
  line-height: 1.3;
}

.c-product-item__image-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: calc(4 / 3) * 100%;
  background: var(--color-white);
  border-radius: var(--border-radius);
}

.c-product-item__figure {
  --spacer: var(--space-m);

  position: absolute;
  top: var(--spacer);
  right: var(--spacer);
  bottom: var(--spacer);
  left: var(--spacer);
}

.c-product-item__image {
  @include image-bg;

  background-size: contain;
  transition: opacity 0.3s, transform 2s cubic-bezier('easeOutQuint') !important;
  transform: scale(1.001);

  .can-hover a:hover & {
    transform: scale(1.025);
  }
}
