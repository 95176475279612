.c-home-collections {
  position: relative;
  padding-top: 0 !important; // prevent .c-main>:first-child
}

.c-home-collections__headline {
  margin-bottom: var(--space-2xl);
  padding: 0 var(--container-padding);
  text-align: center;

  html.is-smooth & {
    display: none;
  }
}

.c-home-collections__subheadline {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: var(--container-padding);
  display: none;
  transform: translateY(-50%);
  font-size: var(--step-1);
  font-weight: 600;

  html.is-smooth & {
    display: block;
  }
}

.c-home-collections__items {
  position: relative;

  html.is-smooth & {
    will-change: transform;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }
}

.c-home-collections__images {
  html.is-smooth & {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
}

.c-home-collections__figure {
  position: relative;
  overflow: hidden;
  padding-bottom: calc(50%);

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.2;
    content: '';
  }

  html.is-smooth & {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;

    &:not(:first-child) {
      transform: translateY(calc(100% - var(--progress, 0) * 100%));
    }
  }
}

.c-home-collections__image {
  position: absolute;
  width: 100%;
  height: 100%;

  html.is-smooth .c-home-collections__figure:not(:first-child) & {
    transform: translateY(calc(-80% + var(--progress, 0) * 80%));
  }

  html.is-smooth .c-home-collections__figure:first-child & {
    transform: translateY(calc(-20% * var(--progress, 0)));
  }
}

.c-home-collections__links {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  html.is-smooth & {
    bottom: auto;
    height: auto;
    will-change: transform;
    padding: calc(50vh - var(--step-5) * 0.5) 0;
    transform: translateY(calc((-100% + 100vh) * var(--progress, 0)));
  }
}

.c-home-collections__link {
  display: flex;
  align-items: center;
  flex: 1 1 0;
  text-decoration: none;

  html.is-smooth & {
    opacity: 0.3;
    transform: scale(0.8);
    transition: opacity 0.6s, transform 1s cubic-bezier('easeOutQuart');

    &:not(:first-child) {
      margin-top: 30vh;
    }
  }

  @for $i from 0 through 10 {
    html.is-smooth .c-home-collections[data-active="#{$i}"] &:nth-child(#{$i + 1}) {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.c-home-collections__title {
  @extend %heading;

  width: 100%;
  font-size: var(--step-5);
  line-height: 1;
  text-align: center;

  html.is-smooth & {
    font-size: var(--step-6);
  }
}

.c-home-collections__scroll-item {
  html.is-smooth & {
    height: 100vh;
  }
}

.c-home-collections__pagination {
  html.is-smooth & {
    position: absolute;
    z-index: 3;
    top: 50%;
    right: var(--container-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-50%);
  }
}

.c-home-collections__bullet {
  html.is-smooth & {
    --size: var(--space-s);

    cursor: pointer;
    position: relative;
    width: var(--size);
    height: var(--size);
    margin: var(--space-2xs) 0;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      border: 2px solid currentcolor;
      border-radius: 50%;
      opacity: 0.5;
      transition: transform 0.3s, opacity 0.3s;
      transform: scale(0.5);
      content: '';
    }
  }

  @for $i from 0 through 10 {
    html.is-smooth .c-home-collections[data-active="#{$i}"] &:nth-child(#{$i + 1}):before {
      transform: scale(1);
      opacity: 1;
    }
  }
}
