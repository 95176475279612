.c-history__container {
  --gutter-y: var(--space-2xl);

  html.is-smooth & {
    --gutter-y: var(--flow);
  }
}

.c-history__item {
  --gutter-y: var(--space-l);
}

.c-history__nav {
  --column-span: 4;
  --column-start: 3;

  position: relative;
  display: none;
  align-self: flex-start;
  will-change: transform;

  html.is-smooth & {
    display: block;
  }
}

.c-history__labels {
  @extend %heading;

  position: relative;
  height: 1.3em;
  font-size: var(--step-2);
  font-weight: 600;
  line-height: 1.3;

  span {
    position: absolute;
    display: block;
    opacity: 0;
    transition: opacity 0.3s;

    &.is-active {
      opacity: 1;
      transition-delay: 0.3s;
    }
  }
}

.c-history__pagination {
  --size: var(--space-s);

  position: absolute;
  top: 50%;
  left: calc(var(--size) * -2);
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(calc((var(--size) * 0.5 + var(--space-2xs)) * -1));

  span {
    position: relative;
    width: var(--size);
    height: var(--size);
    margin: var(--space-2xs) 0;
    cursor: pointer;

    &::before {
      --scale: 0.5;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      border: 2px solid currentcolor;
      border-radius: 50%;
      opacity: 0.5;
      transition: transform 0.3s, opacity 0.3s;
      transform: translateY(calc(var(--index) * -200%)) scale(var(--scale));
      content: '';
    }

    &.is-active::before {
      --scale: 1;

      opacity: 1;
    }
  }
}

.c-history__title {
  font-weight: 600;

  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }

  html.is-smooth & {
    display: none;
  }
}

.c-history__text {
  @include media-breakpoint-up(sm) {
    --column-span: 5;
    --column-start: 7;

    font-size: var(--step-1);
  }

  html.is-smooth & {
    --column-span: 4;

    @extend %heading;

    font-size: var(--step-2);
    font-weight: 400;
    line-height: 1.3;
    opacity: 0.3;
    transition: opacity 0.6s;

    &.is-active {
      opacity: 1;
    }
  }
}
