.c-gallery {
  overflow: hidden;

  .swiper {
    display: grid;
    grid-template-rows: repeat(var(--rows, 1), 1fr);
    grid-template-columns: repeat(var(--columns, #{$grid-columns}), 1fr);
    grid-gap: var(--gutter-y) var(--gutter-x);
    overflow: visible;
    width: 100%;

    @include media-breakpoint-up(sm) {
      --columns: 10;
    }

    > * {
      grid-column: auto / span var(--column-span, 12);
      grid-column-start: var(--column-start, auto);
    }
  }

  .swiper-wrapper {
    @include media-breakpoint-up(sm) {
      --column-start: 1;
      --column-span: 7;
    }
  }

  .swiper-slide {
    &:not(:last-child) {
      margin-right: var(--gutter-x);
    }
  }

  .swiper-pagination {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background: none;
    border: 1px solid var(--color-gray-90);
    opacity: 1;
    transition: background-color 0.3s;
  }

  .swiper-pagination-bullet-active {
    background: var(--color-gray-90);
  }
}

.c-gallery__grid {
  position: relative;
}

.c-gallery__list-wrapper {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 10;

    &:first-child {
      --column-start: 3;
    }
  }
}

.c-gallery__item-content {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: var(--space-m);

  @include media-breakpoint-up(sm) {
    justify-content: flex-end;
  }
}

.c-gallery__item-title {
  font-size: var(--step--1);
}

.c-gallery__item-description {
  font-size: var(--step--2);
  font-weight: 700;
  text-transform: uppercase;
}

.c-gallery__navigation {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--space-l);
    height: var(--space-l);
    color: var(--color-gray-10);
    background: var(--color-gray-90);
    border-radius: 50%;
    transition: opacity 0.3s, background 0.15s, color 0.15s;

    &:hover {
      color: var(--color-gray-90);
      background: var(--color-mariner);
      cursor: pointer;
    }
  }

  & > *:not(:first-child) {
    margin-left: var(--space-xs);
  }

  & > *:first-child {
    .o-icon {
      transform: rotate(180deg);
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.c-gallery__item {
  position: relative;
  overflow: hidden;
  padding-bottom: calc(4 / 3) * 100%;
  background: var(--color-placeholder);
  border-radius: var(--border-radius);

  @include media-breakpoint-up(sm) {
    padding-bottom: 100%;
  }

  &::after {
    @include easing-gradient(to top);

    position: absolute;
    top: 75%;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';

    @include media-breakpoint-down(sm) {
      @include easing-gradient(to bottom);

      top: 0;
      bottom: 75%;
    }
  }
}

.c-gallery__item-image {
  @include image-bg;
}
