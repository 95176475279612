.c-banner {
}

.c-banner__sizer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 100%;
  background: var(--color-placeholder);
  border-radius: var(--border-radius);

  @include media-breakpoint-up(md) {
    padding-bottom: calc(1 / 2) * 100%;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgb(0 0 0 / 0%), rgb(0 0 0 / 100%));
    content: '';
  }
}

.c-banner__cta-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.c-banner__image {
  @include image-bg;
}
