.c-site-header {
  --btn-color: var(--color-white);
  --btn-bg: var(--color-gray-10);

  position: fixed;
  z-index: $z-index-site-header;
  top: 0;
  left: 0;
  width: 100%;
  color: var(--color-gray-90);
  background: var(--color-theme, var(--color-gray-10));
  transition: background 0.3s, color 0.3s, transform 0.4s;
  transform: translate3d(0, 0, 0);

  &::after {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background: currentcolor;
    opacity: 0.15;
    content: '';
  }

  .has-bg-white & {
    color: var(--color-gray-10);
    background: var(--color-gray-90);
  }

  a {
    text-decoration: none;
  }

  .is-active a {
    text-decoration: underline;
  }

  .show-menu & {
    color: var(--color-gray-90) !important;
    background: var(--color-gray-10) !important;
  }

  &.c-site-header--unpinned {
    transform: translate3d(0, -100%, 0);
    pointer-events: none;
  }
}

.c-site-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--site-header-height);
  gap: var(--space-s);
}

.c-site-header__brand {
  display: block;
  fill: currentcolor;

  svg {
    width: auto;
    height: var(--logo-height);
  }
}

.c-site-header__buttons {
  display: flex;
  gap: var(--space-2xs);

  @include media-breakpoint-up(sm) {
    gap: var(--space-xs);
  }
}

.c-site-header__button {
  --btn-font-size: var(--step--1);
  --space-y: 0.5em;
  --space-x: var(--space-xs);

  color: var(--btn-color);
  font-size: var(--btn-font-size);
  background-color: var(--btn-bg);
  border-color: var(--color-gray-70);

  @include media-breakpoint-down(sm) {
    --space-y: calc(var(--space-xs) * 0.85);
    --space-x: calc(var(--space-xs) * 0.85);

    box-sizing: content-box;
    width: var(--space-xs);
    height: var(--space-xs);
    border: 2px solid var(--color-gray-80);
  }

  .o-icon {
    width: var(--btn-font-size) !important;
    height: var(--btn-font-size) !important;
  }

  &:not(.c-site-header__button--menu) {
    background-color: transparent;

    &:hover {
      color: var(--color-white) !important;
      background-color: var(--color-mariner);
    }
  }

  em {
    font-style: normal;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .has-bg-white & {
    color: var(--color-gray-10) !important;
    background-color: transparent;
  }

  .show-menu &:not(.c-site-header__button--menu),
  .show-menu &:not(.c-site-header__button--menu):hover {
    color: var(--color-white) !important;
  }

  &:hover {
    border-color: transparent !important;

    .has-bg-white & {
      border-color: inherit;
    }
  }
}

.c-site-header__button--menu {
  --btn-bg: var(--color-white);
  --btn-color: var(--color-gray-10);

  border-color: transparent !important;

  @include media-breakpoint-up(sm) {
    width: 10ch;

    .o-icon {
      display: none !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .o-icon--close {
      position: absolute;
      width: var(--step--2);
      height: var(--step--2);
      text-align: center;
      visibility: hidden;
    }

    .o-icon--burger {
      display: flex !important;
    }

    .show-menu & {
      .o-icon--close {
        visibility: visible;
      }

      .o-icon--burger {
        visibility: hidden;
      }
    }
  }

  .has-bg-white &,
  .has-bg-white &:hover {
    color: var(--color-white) !important;
    background-color: var(--color-gray-10);
  }

  &:hover,
  .has-bg-white.show-menu & {
    color: var(--color-gray-10) !important;
    background-color: var(--color-white);
  }
}

.c-site-header__open-menu {
  .show-menu & {
    visibility: hidden;
  }
}

.c-site-header__close-menu {
  position: absolute;
  visibility: hidden;

  .show-menu & {
    visibility: visible;
  }
}
