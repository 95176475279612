/* stylelint-disable max-nesting-depth */
.c-collections-list__grid {
  --gutter-y: var(--space-section);

  a {
    text-decoration: none;
  }
}

.c-collections-list__item {
  @include media-breakpoint-up(sm) {
    --column-span: 5;
    --column-start: 2;

    &:nth-child(2n) {
      --column-start: 7;
    }
  }

  .c-collections-list:not(.c-collections-list--peers) & {
    @include media-breakpoint-up(sm) {
      &:nth-child(3n) {
        .c-collections-list__figure {
          padding-bottom: calc(2.5 / 4) * 100%;
        }
      }

      &:nth-child(3n + 2) {
        --column-span: 5;
        --column-start: 8;

        margin-top: var(--space-3xl);
      }

      &:nth-child(3n + 1) {
        --column-span: 6;
        --column-start: 1;

        .c-collections-list__figure {
          padding-bottom: calc(4.5 / 3) * 100%;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &:nth-child(3n + 2) {
        --column-span: 4;
        --column-start: 8;

        margin-top: var(--space-3xl);
      }

      &:nth-child(3n + 1) {
        --column-span: 5;
        --column-start: 2;
      }

      &:nth-child(3n + 3) {
        --column-span: 10;
        --column-start: 2;
      }
    }
  }
}

.c-collections-list__title {
  @include text-shadow(var(--color-gray-10));

  position: relative;
  display: inline-block;
  margin-top: var(--space-m);
  margin-left: var(--space-l);
  font-size: var(--step-3);

  @include media-breakpoint-up(sm) {
    font-size: var(--step-4);
  }

  &::before {
    position: absolute;
    z-index: -1;
    bottom: 0.1em;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--color-gray-90);
    transition: transform 0.5s cubic-bezier('easeOutQuint');
    transform: scaleX(0);
    transform-origin: 100% 100%;
    content: '';

    a:hover & {
      .can-hover & {
        transform: scaleX(1);
        transform-origin: 0 0;
      }
    }
  }
}

.c-collections-list__figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(4 / 3) * 100%;
  background: var(--color-placeholder);
  border-radius: var(--border-radius);
}

.c-collections-list__image {
  @include image-bg;

  transition: opacity 0.3s, transform 2s cubic-bezier('easeOutQuint') !important;
  transform: scale(1.001);

  .can-hover a:hover & {
    transform: scale(1.025);
  }
}
