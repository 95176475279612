.FormBuilder {
  .InputfieldHeader {
    text-transform: uppercase;
  }

  input[type='text'],
  input[type='password'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='week'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='url'],
  input[type='color'],
  input[type='file'],
  textarea {
    border-top: 0;
    border-right: 0;
    border-left: 0;

    &::placeholder {
      font-size: var(--step--2);
      text-transform: uppercase;
    }
  }

  .alert-error {
    color: $form-input-error-color;
    font-size: var(--step-1);
    background: transparent;
  }

  .alert-success {
    @extend %heading;

    color: var(--color-mariner);
    font-size: var(--step-3);
    background: transparent;
    border-radius: var(--border-radius);
  }

  .InputfieldCheckbox {
    font-size: var(--step--2);
    text-transform: uppercase;
    opacity: 0.5;

    .InputfieldHeader {
      display: none !important;
    }
  }

  .InputfieldSubmit {
    display: flex;
    flex-direction: row-reverse;

    button {
      @extend %button;

      align-self: flex-end;
    }
  }
}
