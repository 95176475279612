html {
  // locomotive scroll
  // &.has-scroll-smooth {
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;

  //   [data-scroll-speed] {
  //     will-change: transform;
  //   }
  // }
}

/* stylelint-disable-next-line selector-class-pattern */
.🌿 {
  // locomotive scroll and body scroll lock
  // &:not(.has-scroll-smooth):not(.body-scroll-locked) {
  //   overflow-y: scroll;
  //   overscroll-behavior-y: none;
  // }

  body {
    min-height: 100vh;
    color: var(--color-gray-90);
    background: var(--color-gray-10);
    overscroll-behavior-y: none;

    &.has-bg-white {
      background: var(--color-gray-90);
    }
  }

  &:not(.is-ready) body {
    opacity: 0;

    * {
      transition: none !important;
    }
  }
}

a {
  color: currentcolor;
  outline: none;
}

ul {
  list-style: none;
}

[data-srcset],
[data-bgset] {
  opacity: 0;
  transition: opacity 0.3s;

  &.lazyloaded {
    opacity: 1;
  }
}

[data-bgset] {
  background: center/cover no-repeat;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
