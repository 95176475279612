.c-contact__title {
  max-width: 17.5ch;
  margin-top: var(--space-2xl-3xl);
  font-size: var(--step-5);
}

.c-contact__links {
  margin-top: var(--space-m);

  a {
    font-size: var(--step-1);
    text-decoration: none;

    .can-hover & {
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .o-icon {
    margin-right: var(--space-xs);
  }

  .o-icon--telephone {
    position: relative;
    top: -2px;
  }

  .o-icon--mail {
    position: relative;
    top: -1px;
  }
}

.c-contact__form {
  margin-top: var(--flow);

  @include media-breakpoint-up(sm) {
    --column-start: 4;
    --column-span: 9;

    align-self: flex-end;
  }

  @include media-breakpoint-up(md) {
    --column-start: 6;
    --column-span: 7;
  }

  @include media-breakpoint-up(lg) {
    --column-start: 7;
    --column-span: 6;
  }
}
