%measure {
  max-width: var(--measure, 100%);
}

%heading {
  font-weight: 500;
  line-height: calc(1.08em + 4px);
  letter-spacing: -0.03em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %heading;
  @extend %measure;
}

p {
  @extend %measure;
}

h1 {
  font-size: var(--step-6);
}

h2 {
  font-size: var(--step-3);
}

h3 {
  font-size: var(--step-2);
}

h4 {
  font-size: var(--step-1);
  line-height: 1.35;
  letter-spacing: 0;
}

h5 {
  font-size: var(--step-0);
  line-height: 1.35;
  letter-spacing: 0;
}

h6 {
  font-size: var(--step--1);
  letter-spacing: 0;
}
