.c-gallery-thumbnail {
}

.c-gallery-thumbnail__wrapper {
  overflow: hidden;
  background: var(--color-white);
  border-radius: var(--border-radius);
}

.c-gallery-thumbnail__pagination {
  position: relative !important;
  z-index: 10;
  bottom: 0 !important;
  margin-top: calc(-1 * var(--space-xs));
  padding: var(--space-xs) var(--space-xs) 0 0;
  line-height: 0;
  text-align: left !important;
  transform: none !important;

  .c-gallery-thumbnail__item-image {
    --spacer: var(--space-2xs-xs);
  }
}

.c-gallery-thumbnail__bullet {
  position: relative;
  overflow: hidden;
  width: var(--space-xl) !important;
  height: var(--space-xl) !important;
  margin: 0 !important;
  margin-top: var(--space-xs) !important;
  background: var(--color-white) !important;
  border-radius: 20% !important;
  opacity: 1 !important;
  transform: none !important;

  &:not(:last-child) {
    margin-right: var(--space-xs) !important;
  }

  &.swiper-pagination-bullet-active {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid var(--color-gray-10);
      border-radius: 20%;
      content: '';
    }
  }
}

.c-gallery-thumbnail__zoom {
  cursor: zoom-in;
}

.c-gallery-thumbnail__item {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background: var(--color-white);
}

.c-gallery-thumbnail__text-wrapper {
  overflow: visible !important;
  pointer-events: none !important;
}

.c-gallery-thumbnail__text {
  pointer-events: none !important;
  background: var(--color-gray-90);

  @include media-breakpoint-up(lg) {
    margin-left: var(--space-m);
  }

  & > * {
    opacity: 0;
    transition: opacity 0.1s;
  }

  &.is-active > * {
    opacity: 1;
    transition: opacity 0.6s 0.05s;
  }
}

.c-gallery-thumbnail__code {
  display: inline-block;
  margin-top: var(--space-s-m);
  font-size: var(--step--1);
  font-weight: 600;
  text-transform: uppercase;

  .is-active & {
    pointer-events: auto;
  }
}

.c-gallery-thumbnail__description {
  display: block;

  // padding-top: var(--space-2xs);
  color: var(--color-gray-50);
  font-size: var(--step--1);

  @include media-breakpoint-down(sm) {
    padding-bottom: var(--space-s);
  }
}

.c-gallery-thumbnail__item-image {
  --spacer: var(--space-2xl-3xl);

  position: absolute;
  top: var(--spacer);
  right: var(--spacer);
  bottom: var(--spacer);
  left: var(--spacer);
  background-size: contain;
}
