@mixin image-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin easing-gradient($text) {
  background:
    linear-gradient(
      $text,
      hsl(0deg 0% 0% / 50%) 0%,
      hsl(0deg 0% 0% / 49.4%) 8.1%,
      hsl(0deg 0% 0% / 47.6%) 15.5%,
      hsl(0deg 0% 0% / 44.8%) 22.5%,
      hsl(0deg 0% 0% / 41.2%) 29%,
      hsl(0deg 0% 0% / 37%) 35.3%,
      hsl(0deg 0% 0% / 32.4%) 41.2%,
      hsl(0deg 0% 0% / 27.5%) 47.1%,
      hsl(0deg 0% 0% / 22.5%) 52.9%,
      hsl(0deg 0% 0% / 17.6%) 58.8%,
      hsl(0deg 0% 0% / 13%) 64.7%,
      hsl(0deg 0% 0% / 8.8%) 71%,
      hsl(0deg 0% 0% / 5.2%) 77.5%,
      hsl(0deg 0% 0% / 2.4%) 84.5%,
      hsl(0deg 0% 0% / 0.6%) 91.9%,
      hsl(0deg 0% 0% / 0%) 100%
    );
}

@mixin text-shadow($color) {
  text-shadow:
    0.03em 0 $color,
    -0.03em 0 $color,
    0 0.03em $color,
    0 -0.03em $color,
    0.06em 0 $color,
    -0.06em 0 $color,
    0.09em 0 $color,
    -0.09em 0 $color,
    0.12em 0 $color,
    -0.12em 0 $color,
    0.15em 0 $color,
    -0.15em 0 $color;
}
