.c-main {
  --flow: var(--space-section);

  @extend %flow;

  scroll-snap-align: start;

  & > *:first-child {
    padding-top: var(--site-header-height);
  }

  & > .c-video:first-child {
    padding-top: calc(var(--site-header-height) + var(--space-s));
  }
}
