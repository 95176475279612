.c-post {
  --flow: var(--space-section);

  @extend %flow;
}

.c-post__meta {
  --meta-p: var(--space-3xs);

  display: flex;
  margin-top: var(--space-xl);
  font-size: var(--step--1);
  text-transform: uppercase;
  opacity: 0.5;
  gap: var(--meta-p);

  li:not(:last-child)::after {
    content: '/';
    margin-left: var(--meta-p);
  }

  + * {
    --flow: var(--space-s);
  }
}
