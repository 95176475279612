.c-text-images {
  --gutter-y: var(--space-2xl);
}

.c-text-images__text {
  @include media-breakpoint-up(sm) {
    --column-span: 8;
    --column-start: 3;
  }

  @include media-breakpoint-up(md) {
    --column-span: 5;
    --column-start: 1;

    html:not(.is-smooth) & {
      position: sticky;
      top: calc(var(--site-header-height) + var(--gutter-y));
      align-self: flex-start;
    }

    html.is-smooth & {
      --column-span: 4;

      padding-top: var(--gutter-y);
    }
  }
}

.c-text-images__images {
  @include media-breakpoint-up(md) {
    --column-span: 6;
    --column-start: 7;

    html.is-smooth & {
      --column-span: 7;
      --column-start: 6;
      --gutter-y: 0;

      position: relative;
      display: block;
      overflow: hidden;
      height: calc(100vh - var(--space-2xl) * 2);
      margin-right: calc(var(--container-padding) * -1);
      border-radius: var(--border-radius) 0 0 var(--border-radius);
    }
  }
}

.c-text-images__image-wrapper {
  --column-start: auto;
  --column-span: 12;

  @include media-breakpoint-down(sm) {
    &:nth-child(n+2) {
      display: none;
    }
  }

  @include media-breakpoint-only(sm) {
    @include quantity-at-least(2) {
      --column-span: 6;
    }
  }

  @include media-breakpoint-up(md) {
    html.is-smooth & {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      transform: translateY(calc(100% - 100% * var(--progress, 0))) translateZ(0);

      &:first-child {
        transform: none;
      }
    }
  }
}

.c-text-images__figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(3 / 4) * 100%;
  background: var(--color-placeholder);
  border-radius: var(--border-radius);

  @include media-breakpoint-up(md) {
    html.is-smooth & {
      position: static;
      padding-bottom: 0;
      border-radius: 0;
    }
  }
}

.c-text-images__image {
  @include image-bg;

  @include media-breakpoint-up(md) {
    html.is-smooth & {
      transform: translateY(calc(-80% + 80% * var(--progress, 0))) translateZ(0);
    }

    .c-text-images__image-wrapper:first-child & {
      transform: none;
    }
  }
}
