.c-search {
  color: var(--color-gray-10);

  @include media-breakpoint-up(md) {
    [data-ref='filter-toggle.open'] {
      pointer-events: none;
    }
  }
}

.c-search__title {
  display: flex;
  align-items: flex-start;
  margin: var(--space-2xl-3xl) 0 var(--space-3xl-4xl) 0;

  span {
    @extend %heading;

    font-size: var(--step-6);
  }

  sup {
    @extend %heading;

    margin-left: var(--space-2xs);
    color: var(--color-gray-60);
    font-size: var(--step-0);
    font-weight: 400;
  }
}

.c-search__top-grid {
  --gutter-y: 0;

  min-height: calc(var(--step--2) + 0.75em * 2 + 2px);
  font-size: var(--step--2);
}

.c-search__bottom-grid {
  margin-top: var(--space-l);

  @include media-breakpoint-down(md) {
    margin-top: var(--space-xl);
  }
}

.c-search__label {
  line-height: 1;

  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(var(--space-xs) * 1.4);
    padding-bottom: var(--space-xs);
    border-bottom: 1px solid var(--color-gray-80);
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    --column-span: 3;

    .o-icon {
      display: none;
    }
  }
}

.c-search__active-filters {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--space-xs));
  margin-left: calc(var(--space-2xs) * -1.2);

  @include media-breakpoint-up(md) {
    --column-span: 9;
  }

  a * {
    pointer-events: none;
  }
}

.c-search__active-filter {
  margin-top: var(--space-xs);
  margin-left: calc(var(--space-2xs) * 1.2);

  .o-icon {
    stroke-width: 1.4px;
    margin-left: var(--space-2xs);
  }

  a {
    display: flex;
    align-items: center;
    padding: 0.65em 1.25em;
    font-size: var(--step--2);
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid var(--color-gray-60);
    border-radius: 999px;
  }
}

.c-search__main {
  @include media-breakpoint-down(md) {
    position: fixed;
    z-index: $z-index-filters;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    pointer-events: none;
    transform: translateX(100%);

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgb(0 0 0 / 40%);
      content: '';
      opacity: 0;
      transition: opacity 0.4s;
      transform: translateX(-100%);
    }

    .show-filters & {
      pointer-events: auto;

      &::before {
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }

  @include media-breakpoint-up(md) {
    --column-span: 3;
  }
}

.c-search__button-close {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: calc(100% - calc(var(--container-padding) * 2));
  height: var(--site-header-height);
  padding-right: var(--container-padding);
  background: var(--color-white);
  border-bottom: 1px solid var(--color-gray-80);
  transition: transform 0.4s;
  pointer-events: auto;

  @include media-breakpoint-up(md) {
    display: none;
  }

  .o-icon {
    margin-left: auto;
  }

  .show-filters & {
    transform: translateX(calc(-100%));
  }
}

.c-search__main-inner {
  @include media-breakpoint-down(md) {
    --top-element-space: var(--container-padding) * 2 + var(--step-2);

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
    width: calc(100% - calc(var(--container-padding) * 2));
    padding: calc(var(--top-element-space) + var(--space-m) + var(--space-xs)) var(--container-padding) var(--container-padding) var(--container-padding);
    background: var(--color-white);
    transition: transform 0.4s;
    pointer-events: auto;
    scroll-behavior: smooth;

    .show-filters & {
      transform: translateX(-100%);
    }
  }
}

.c-search__form {
  line-height: 1;

  input {
    width: 100%;
    margin-top: var(--space-xs);
    padding: var(--space-xs) 1em;
    font-size: var(--step--1);
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background: var(--color-gray-90);
    border: 1px solid var(--color-gray-60);
    border-radius: 5px;
    outline: none;
    appearance: none;

    @include media-breakpoint-down(md) {
      background: var(--color-white);
    }

    &:focus {
      border-color: var(--color-gray-10);
    }

    &::placeholder {
      color: var(--color-gray-60);
    }
  }
}

.c-search__filters {
  margin-top: var(--space-m);
}

.c-search__filter-item {
  border-bottom: 1px solid var(--color-gray-80);
}

.c-search__filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-xs) 0;
  line-height: 1;
  cursor: pointer;

  .is-open & .o-icon {
    transform: rotate(180deg);
  }
}

.c-search__filter-content {
  overflow: hidden;
  height: 0;
}

.c-search__filter-inner-items {
  display: flex;

  .o-icon {
    width: calc(var(--step-0) + 1px);
    height: calc(var(--step-0) + 1px);
    margin-right: var(--space-3xs);
    color: var(--color-gray-90);
    background: var(--color-gray-90);
    border: 1px solid var(--color-gray-60);
    border-radius: 4px;
  }

  .is-active .o-icon {
    background: var(--color-gray-10);
    border-color: var(--color-gray-10);
  }

  a {
    margin-left: var(--space-3xs);
    padding: var(--space-3xs) 0;
    font-size: var(--step--1);
    text-decoration: none;

    * {
      pointer-events: none;
    }
  }

  &:first-child a {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: var(--space-xs);
  }
}

.c-search__items {
  --columns: 12;
  --column-span: 12;

  @include media-breakpoint-up(md) {
    --columns: 9;
    --column-span: 9;
  }
}

.c-search__item {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  @include media-breakpoint-up(md) {
    --column-span: 3;
  }
}
