.c-page-header-text {
  & + * {
    --flow: var(--space-5xl);
  }
}

.c-page-header-text--detail {
  & + * {
    --flow: var(--space-2xl);
  }
}

.c-page-header-text__title {
  --measure: 24ch;

  margin-top: var(--space-6xl);
  color: var(--color-white);
  font-size: var(--step-6);

  .c-page-header-text--detail &,
  .c-post & {
    font-size: var(--step-5);
  }
}

.c-page-header-text__subtitle {
  --index: 2;

  margin-top: var(--space-xs);
  color: var(--color-gray-60);
  font-size: var(--step--1);
  text-transform: uppercase;

  .c-post & {
    margin-top: var(--space-xl);
  }
}
