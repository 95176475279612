.o-breadcrumb {
  margin-top: var(--space-s);

  ul {
    display: flex;
  }

  li {
    font-size: var(--step--1);
    font-weight: 700;
    text-transform: uppercase;

    &:not(:first-child)::before {
      content: '/';
      margin: 0 0.5em;
    }

    &:last-child a {
      pointer-events: none;
      opacity: 1;
    }

    a {
      text-decoration: none;
      opacity: var(--opacity-inactive);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
