.c-home-intro {
  h2 {
    font-size: var(--step-4);
  }
}

.c-home-intro__media {
  align-self: center;

  @include media-breakpoint-up(md) {
    --column-span: 6;
  }

  @include media-breakpoint-up(md) {
    --column-start: 2;
    --column-span: 5;
  }
}

.c-home-intro__body {
  align-self: center;

  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  @include media-breakpoint-up(md) {
    --column-span: 5;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 4;
  }
}

.c-home-intro__figure {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background: var(--color-placeholder);
  border-radius: var(--border-radius);
}

.c-home-intro__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
