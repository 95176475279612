.c-text-award {

  --grid-margin: var(--space-2xl);

  .c-images + &,
  .c-banner + & {
    --flow: var(--space-2xl);
  }

  .o-grid {
    --gutter-y: 0;
  }
}

.c-text-award__col {
  @include media-breakpoint-up(sm) {
    --column-span: 8;
    --column-start: 3;
  }

  @include media-breakpoint-up(md) {
    --column-span: 5;
  }
}

.c-text-award__col--award {
  @include media-breakpoint-up(md) {
    --column-start: 1;
  }
}

.c-text-award__col--body {
  @include media-breakpoint-up(md) {
    --column-start: 7;
  }

  @include media-breakpoint-down(md) {
    &:not(:first-child) {
      margin-top: var(--grid-margin);
    }
  }
}

.c-text-award__image {
  width: auto;
  height: var(--step-6);

  @include media-breakpoint-up(md) {
    &:first-child {
      margin-right: calc(var(--column-width-gutter) * -1);
      margin-left: auto;
    }
  }
}

.c-text-award__cta {
  --measure: 32ch;
}

.c-text-award__link {
  font-size: var(--step-1);
  text-decoration-color: var(--color-gray-30);
}
