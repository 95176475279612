.c-banner-download {
  a {
    text-decoration: none;
  }

  & + .c-site-footer {
    --flow: 0;
  }
}

.c-banner-download__container {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--space-2xl-3xl);
  padding-bottom: var(--space-2xl-3xl);
  text-align: center;
  background: var(--color-theme);

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    filter: brightness(0.75);
    transform: scaleY(0);
    transition: transform 1s cubic-bezier('easeOutQuint');
    transform-origin: 0 0;
    content: '';
  }

  &:hover::before {
    transform: scaleY(1);
  }
}

.c-banner-download__title {
  font-size: var(--step-3);

  span {
    margin-right: 0.25em;
    opacity: 0.5;

    @include media-breakpoint-down(sm) {
      display: block;
      margin-right: 0;
    }
  }
}

.c-banner-download__icon {
  margin: var(--space-2xs) 0 0;

  @include media-breakpoint-up(sm) {
    margin: 0 0 0 1em;
  }

  .o-icon {
    width: var(--step-4);
    height: var(--step-4);

    @include media-breakpoint-up(sm) {
      stroke-width: 1px;
    }
  }
}
