%icon-social {
  display: block;
  padding: var(--space-2xs);
  color: var(--color-gray-90) !important;
  background: transparent;
  border: 1px solid currentcolor;
  border-radius: 100%;

  .can-hover & {
    transition: background-color 0.2s, border-color 0.2s !important;

    &:hover {
      background: var(--color-mariner);
      border-color: var(--color-mariner);
    }
  }
}

.c-site-footer {
  --flow: var(--space-6xl);

  padding-bottom: var(--space-2xs);
  background: var(--color-gray-15);

  a {
    text-decoration: none;

    .can-hover & {
      transition: color 0.2s;

      &:hover {
        color: var(--color-mariner);
        opacity: 1;
      }
    }
  }
}

.c-site-footer__grid-top {
  --gutter-y: 0;

  @extend %flow;

  padding: var(--space-3xl) 0;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.c-site-footer__nav-primary {
  @include media-breakpoint-up(sm) {
    --column-span: 5;
  }

  li {
    font-size: var(--step-3);
    opacity: var(--opacity-active);
  }
}

.c-site-footer__nav-secondary {
  --flow: var(--space-2xl);

  font-size: var(--step-0);

  @include media-breakpoint-up(sm) {
    --flow: 0;
    --column-span: 3;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  a {
    display: inline-block;
    line-height: 2;
  }

  li {
    opacity: var(--opacity-active);
  }
}

.c-site-footer__product-search {
  display: inline-block;
  margin-top: var(--space-m);
}

.c-site-footer__nav-generic {
  --flow: var(--space-3xl);

  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(sm) {
    --flow: 0;
    --column-span: 4;

    align-items: flex-end;
    text-align: right;
  }
}

.c-site-footer__list-social {
  ul {
    display: flex;
    justify-content: center;
    margin-top: var(--space-2xl);

    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
      margin-top: 0;
    }
  }

  li {
    line-height: 0;

    a {
      @extend %icon-social;
    }

    &:not(:last-child) {
      margin-right: var(--space-s);
    }
  }
}

.c-site-footer__download {
  margin-top: var(--space-2xl);
  font-size: var(--step--1);

  @include media-breakpoint-down(sm) {
    order: -1;
    margin-top: 0;
    font-size: var(--step-0);
  }
}

.c-site-footer__grid-bottom {
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.c-site-footer__colophon-left,
.c-site-footer__colophon-center,
.c-site-footer__colophon-right {
  color: var(--color-gray-60);
  font-size: var(--step--2);
}

.c-site-footer__colophon-left {
  @include media-breakpoint-up(sm) {
    --column-span: 5;
  }
}

.c-site-footer__colophon-center {
  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }
}

.c-site-footer__colophon-right {
  font-weight: 500;

  @include media-breakpoint-up(sm) {
    --column-span: 3;

    text-align: right;
  }
}
