.c-text {
  --gutter-y: var(--space-2xl);

  .c-images + &,
  .c-banner + & {
    --flow: var(--space-2xl);
  }
}

.c-text__col {
  @include media-breakpoint-up(sm) {
    --column-span: 8;
    --column-start: 3;
  }

  @include media-breakpoint-up(md) {
    --column-span: 5;
  }
}

.c-text__col--1 {
  @include media-breakpoint-up(md) {
    --column-start: 1;
  }
}

.c-text__col--2 {
  @include media-breakpoint-up(md) {
    --column-start: 7;
  }
}
