.c-home-slider {
  overflow: hidden;

  &.c-home-slider--desktop {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &.c-home-slider--mobile {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .swiper {
    --margin-x: calc(var(--gutter-x) * 0.5);

    overflow: visible;
    margin: 0 var(--margin-x);

    @include media-breakpoint-up(sm) {
      padding: calc(var(--step-6) * 0.6) 0 calc(var(--step-6) * 0.4);
    }
  }

  .swiper-wrapper {
    html:not(.is-mobile) & {
      transition-timing-function: cubic-bezier('easeOutQuint');
    }
  }

  .swiper-slide {
    position: relative;
    display: flex;
    overflow: hidden;
    margin: 0 calc(var(--gutter-x) * 0.5);
    border-radius: var(--border-radius);

    @include media-breakpoint-up(sm) {
      width: calc(var(--column-width-gutter) * 10);
      opacity: 0.3;
      transition: opacity 0.6s !important;

      &.swiper-slide-active,
      &.swiper-slide-duplicate-active {
        opacity: 1;
      }
    }
  }

  .swiper-pagination {
    @include media-breakpoint-up(sm) {
      display: none !important;
    }
  }

  .swiper-pagination-bullet {
    background: transparent;
    border: 1px solid currentcolor;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: currentcolor;
  }

  .swiper-pagination:not(.swiper-pagination-lock) {
    position: static;
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-s);
  }
}

.c-home-slider__main {
  position: relative;
  margin-top: var(--container-padding);

  @include media-breakpoint-up(sm) {
    margin-top: var(--space-xl);
  }
}

.c-home-slider__headline {
  pointer-events: none;
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--container-padding) calc(var(--gutter-x) * 1.5);

  @include media-breakpoint-up(sm) {
    top: 0;
    padding: 0;
  }
}

.c-home-slider__title {
  font-size: var(--step-6);
  font-weight: 600;

  @include media-breakpoint-up(sm) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
  }

  span {
    transition: transform 2s cubic-bezier('easeOutQuart'), opacity 1s;
    transform: translateY(0.25em);
  }

  span:first-child {
    transition-delay: 0.5s;
  }

  span:last-child {
    transition-delay: 1s;
  }
}

.c-home-slider__image {
  flex-grow: 1;
  padding-bottom: calc(3 / 2 * 100%);
  transform: scale(1.1);
  transition: opacity 2s, transform 3s cubic-bezier('easeOutQuint');

  @include media-breakpoint-up(sm) {
    padding-bottom: min(65vh, calc(8 / 16 * 100%));
  }

  &.lazyloaded {
    opacity: 1;
    transform: scale(1.001);
  }
}

.c-home-slider__footer {
  margin-top: var(--container-padding);
  text-align: right;

  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
    margin-top: var(--space-l);
  }

  span:first-child {
    --stagger: 5;

    font-weight: 600;
  }

  span:last-child {
    --stagger: 5;

    opacity: 0.5;
  }
}
