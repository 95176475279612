.c-product-list {
  padding-top: var(--space-section);
  padding-bottom: var(--space-section);
  color: var(--color-gray-10);
  background: var(--color-gray-90);

  a {
    text-decoration: none;
  }

  .c-page-header-collection + & {
    --flow: 0;
  }

  & + .c-site-footer {
    --flow: 0;
  }

  & + .c-banner-download {
    --flow: 0;
  }

  .c-finishes {
    margin-top: var(--space-xl);
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .MarkupPagerNav {
    display: none;
  }
}

.c-product-list__headline {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: var(--step-5);

  > * {
    @extend %heading;
  }
}

.c-product-list__title,
.c-product-list__collection {
  // @include media-breakpoint-up(sm){
  //   font-size: var(--step-6);
  // }
}

.c-product-list__collection {
  margin-left: 0.25em;
  color: var(--color-theme);
  font-weight: 700;

  // @include media-breakpoint-down(sm) {
  //   flex-basis: 100%;
  //   order: 2;
  // }

  @include media-breakpoint-up(sm) {
  }
}

.c-product-list__count {
  margin-left: 0.75em;
  padding-top: 0.2em;
  font-size: 40%;

  @include media-breakpoint-up(sm) {
    font-size: 35%;
  }
}

.c-product-list__grid {
  --gutter-y: var(--space-2xl-3xl);

  margin-top: var(--space-2xl);
}

.c-product-list__item {
  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }

  @include media-breakpoint-up(md) {
    --column-span: 3;
  }
}

.c-product-list__more {
  display: none;
  margin-top: var(--space-2xl);
  text-align: center;

  .has-load-more & {
    display: block;
  }
}
