.c-gallery-collections {
  overflow: hidden;

  .swiper {
    display: grid;
    grid-template-rows: repeat(var(--rows, 1), 1fr);
    grid-template-columns: repeat(var(--columns, #{$grid-columns}), 1fr);
    grid-gap: var(--gutter-y) var(--gutter-x);
    overflow: visible;
    width: 100%;

    @include media-breakpoint-up(sm) {
      --columns: 11;
    }

    > * {
      grid-column: auto / span var(--column-span, 12);
      grid-column-start: var(--column-start, auto);
    }
  }

  .swiper-wrapper {
    --column-span: 10;

    @include media-breakpoint-up(sm) {
      --column-span: 5;
    }

    @include media-breakpoint-up(md) {
      --column-start: 1;
      --column-span: 4;
    }
  }

  .swiper-slide {
    &:not(:last-child) {
      margin-right: var(--gutter-x);
    }
  }
}

.c-gallery-collections__grid {
  position: relative;
}

.c-gallery-collections__title {
  margin-bottom: var(--space-l);
  font-size: var(--step-5);

  @include media-breakpoint-up(md) {
    --column-start: 2;
  }
}

.c-gallery-collections__list-wrapper {
  --column-span: 12;

  @include media-breakpoint-up(md) {
    --column-span: 11;

    &:first-child {
      --column-start: 2;
    }
  }
}

.c-gallery-collections__item-content {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: var(--space-m);
  transform: translate(-50%, -50%);

  @include media-breakpoint-up(sm) {
    justify-content: flex-end;
  }
}

.c-gallery-collections__item-title {
  font-size: var(--step-3);
  font-weight: 600;
  line-height: 1;
  text-align: center;
}

.c-gallery-collections__navigation {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;
  display: flex;
  transform: translateY(-50%);

  @include media-breakpoint-down(sm) {
    display: none;
  }

  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--space-l);
    height: var(--space-l);
    color: var(--color-gray-10);
    background: var(--color-gray-90);
    border-radius: 50%;
    transition: opacity 0.3s, background 0.15s, color 0.15s;

    &:hover {
      color: var(--color-gray-90);
      background: var(--color-mariner);
      cursor: pointer;
    }
  }

  & > *:not(:first-child) {
    margin-left: var(--space-xs);
  }

  & > *:first-child {
    .o-icon {
      transform: rotate(180deg);
    }
  }

  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0;
  }
}

.c-gallery-collections__item {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background: var(--color-placeholder);
  border-radius: var(--border-radius);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-black);
    opacity: 0.3;
    content: '';
  }
}

.c-gallery-collections__item-image {
  @include image-bg;
}
