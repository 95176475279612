:root {
  --body-font-family: 'Public Sans', sans-serif;
  --body-line-height: 1.6;
  --heading-font-family: 'Public Sans', sans-serif;

  // --placeholder-color: #eee;
  --opacity-active: 0.88;
  --opacity-inactive: 0.56;
  --site-header-padding: var(--space-m-l);
  --logo-height: var(--step-1);
  --site-header-height: calc(var(--site-header-padding) * 2 + var(--logo-height));
  --space-section: var(--space-3xl-6xl);
  --border-radius: var(--space-xs-s);
  --color-placeholder: rgb(100 100 100 / 10%);
  --column-width: calc((100vw - var(--container-padding) * 2 - var(--gutter-x) * 11) / 12);
  --column-width-gutter: calc(var(--column-width) + var(--gutter-x));
}
