%button {
  --space-y: 0.5em;
  --space-x: 1.5em;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-y) var(--space-x);
  text-decoration: none;
  background: transparent;
  border: 1px solid currentcolor;
  border-radius: 999px;
  appearance: none;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s !important;

  * {
    pointer-events: none;
  }

  &:hover {
    color: var(--color-gray-90) !important;
    background: var(--color-mariner);
    border-color: var(--color-mariner);
  }
}

.o-button {
  @extend %button;
}

.o-button--download {
  &::before {
    width: var(--step-0);
    height: var(--step-0);
    margin-right: 0.3em;
    background-image: url(svg('download', 'fill: none; stroke: #{map-get($colors, white)};'));
    background-position: center;
    background-repeat: no-repeat;
    stroke: currentcolor;
    content: '';
  }
}

.o-button__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 1.1em;
  line-height: 1em;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   left: 0;
  //   width: 100%;
  //   height: 1px;
  //   background: red;
  //   transform: translateY(-50%);
  // }

  span {
    transition: transform 0.5s cubic-bezier('easeOutQuint');

    &:first-child {
      transition-delay: 0.1s;
    }

    &:last-child {
      margin-top: 0.25em;
      transition-delay: 0s;
    }

    .o-button--icon & {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
    }
  }

  .o-button:hover & span,
  button:hover & span {
    will-change: transform;
    transform: translateY(0);

    .can-hover & {
      transform: translateY(calc(-1em - 0.2em));

      &:first-child {
        transition-delay: 0s;
      }

      &:last-child {
        transition-delay: 0.1s;
      }
    }
  }
}
