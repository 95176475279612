.c-images__grid {
  --gutter-y: var(--space-2xl);
}

.c-images__figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(2 / 4) * 100%;
  background: var(--color-placeholder);
  border-radius: var(--border-radius);

  .c-images--no-bg & {
    padding-bottom: 0;

    &:not(:only-child) {
      padding-bottom: 0;
    }
  }

  &:not(:only-child) {
    padding-bottom: 100%;

    @include media-breakpoint-up(sm) {
      --column-span: 6;
    }
  }
}

.c-images__image-bg {
  @include image-bg;

  .c-images--no-bg & {
    display: none;
  }
}

.c-images__image {
  display: none;

  .c-images--no-bg & {
    display: block;
  }
}
