.fancybox__nav,
.fancybox__toolbar {
  --carousel-button-svg-filter: none;
  --carousel-button-width: var(--space-m);
  --carousel-button-height: var(--space-m);
  --carousel-button-svg-width: var(--step-0);
  --carousel-button-svg-height: var(--step-0);
  --carousel-button-svg-stroke-width: 2px;
}

.fancybox__container {
  --fancybox-accent-color: #000;
  --fancybox-thumbs-ratio: 1;
  --fancybox-thumbs-width: var(--space-xl);

  z-index: $z-index-fancybox;

  * {
    &:focus {
      outline: none !important;
    }
  }
}

.fancybox__backdrop {
  background: var(--color-black);
}

.fancybox__content {
  &:focus:not(.carousel__button.is-close) {
    outline: none;
  }
}

.fancybox__viewport {
  opacity: 0;
  transition: opacity 1s;

  .with-fancybox & {
    opacity: 1;
  }
}

.fancybox__toolbar {
  padding: var(--space-s) var(--container-padding) !important;
}

.carousel__button.is-next {
  right: var(--container-padding) !important;
}

.carousel__button.is-prev {
  left: var(--container-padding) !important;
}

.fancybox__thumbs .carousel__slide {
  position: relative;
  overflow: hidden;
  height: var(--fancybox-thumbs-width);
  margin: var(--space-xs);
  padding: 0;
  background: #fff;
  border-radius: 20%;

  &.is-nav-selected {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid #000;
      border-radius: inherit;
      content: '';
    }
  }
}

.fancybox__thumb {
  --spacer: var(--space-2xs-xs);

  position: absolute;
  top: var(--spacer);
  right: var(--spacer);
  bottom: var(--spacer);
  left: var(--spacer);
  width: auto;
  height: auto;
  padding: 0;
  background-size: contain;

  &::after {
    display: none;
  }
}

.fancybox__nav {
  .is-mobile & {
    display: none;
  }
}

.fancybox--gallery {
  --carousel-button-color: var(--color-gray-10);

  .fancybox__slide {
    background: #fff;
  }

  .fancybox__backdrop {
    background: var(--color-gray-90);
  }
}
