.c-post-list {
  margin-top: var(--space-xl);

  .c-post & {
    margin-top: var(--space-section);
  }
}

.c-post-list__title {
  margin-bottom: var(--space-m);
  font-size: var(--step-5);
}

.c-post-list__items {
  --gutter-y: var(--space-2xl-3xl);

  list-style: none;
}

.c-post-list__item {
  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  .three-cols-grid & {
    @include media-breakpoint-up(md) {
      --column-span: 4;
    }

    @include media-breakpoint-only(sm) {
      &:nth-child(n+3) {
        display: none;
      }
    }
  }
}

.c-posts-list__item-link {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  gap: var(--space-xs);
}

.c-posts-list__item-figure {
  position: relative;
  overflow: hidden;
  padding-bottom: calc(3 / 4) * 100%;
  background: var(--color-placeholder);

  .three-cols-grid & {
    border-radius: var(--border-radius);
  }
}

.c-posts-list__item-image {
  @include image-bg;

  transition: transform 2s cubic-bezier('easeOutQuint') !important;
  transform: scale(1.001);

  .can-hover a:hover & {
    transform: scale(1.025);
  }
}

.c-post-list__item-title {
  font-size: var(--step-2);

  span {
    @include text-shadow(var(--color-gray-10));

    .can-hover & {
      background-image: linear-gradient(currentcolor, currentcolor);
      background-position: 100% 95%;
      background-repeat: no-repeat;
      background-size: 0 2px;
      transition: background-size 0.75s cubic-bezier('easeOutQuint');
    }

    .can-hover a:hover & {
      background-position: 0% 95%;
      background-size: 100% 2px;
    }
  }
}

.c-post-list__item-meta {
  --meta-p: var(--space-3xs);

  display: flex;
  font-size: var(--step--1);
  text-transform: uppercase;
  opacity: 0.5;
  gap: var(--meta-p);

  li:not(:last-child)::after {
    content: '/';
    margin-left: var(--meta-p);
  }
}

.c-post-list__cta {
  margin-top: var(--space-xl);
}
