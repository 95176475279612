.MarkupPagerNav {
  --padding-y: 0.5em;
  --padding-x: 1.5em;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--space-section-main);
  margin-top: var(--flow);
  color: var(--color-gray-10);
  font-size: var(--step-0);
  font-weight: 500;
  text-transform: uppercase;
  list-style: none;

  @include media-breakpoint-down(sm) {
    li:not(.MarkupPagerNavPrevious):not(.MarkupPagerNavOn):not(.MarkupPagerNavNext) {
      display: none;
    }
  }

  .c-posts & {
    color: var(--color-white);
  }

  > * {
    margin: 0 var(--space-3xs);
  }

  a {
    display: block;
    min-width: 3.6em;
    padding: var(--padding-y) var(--padding-x);
    line-height: 1;
    text-align: center;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 999px;

    &:hover {
      .can-hover & {
        border: 1px solid currentcolor;
      }
    }
  }

  .MarkupPagerNavOn a {
    border: 1px solid var(--color-mariner);
  }
}
