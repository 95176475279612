.c-product-detail {
  margin-top: var(--space-s);
  color: var(--color-gray-10);

  & + *:not(.c-site-footer) {
    margin-top: 0;
  }

  .o-breadcrumb {
    margin-top: 0;
  }

  .c-finishes {
    margin-top: var(--space-l);
  }
}

.c-product-detail__breadcrumb {
  margin-bottom: var(--space-2xl);
}

.c-product-detail__grid {
  --gutter-y: 0;

  grid-template-rows: auto auto auto;

  @include media-breakpoint-up(sm) {
    grid-template-rows: auto 1fr;
  }
}

.c-product-detail__left {
  --column-span: 12;

  grid-row: 2/3;

  @include media-breakpoint-up(sm) {
    --column-span: 5;

    grid-row: 1/3;
  }
}

.c-product-detail__gallery {
  position: sticky;
  top: calc(var(--site-header-height) + var(--container-padding));

  @include media-breakpoint-down(sm) {
    margin-top: var(--space-m);
  }
}

.c-product-detail__right-top,
.c-product-detail__right-bottom {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 6;
    --column-start: 7;
  }

  @include media-breakpoint-up(md) {
    --column-span: 5;
    --column-start: 7;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 4;
    --column-start: 8;
  }
}

.c-product-detail__right-top {
  grid-row: 1/2;
}

.c-product-detail__title {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--step-2);
  font-weight: 600;
  text-transform: uppercase;
  column-gap: 0.5em;
}

.c-product-detail__description {
  margin-top: var(--space-3xs);
  font-size: var(--step-1);
  font-weight: 600;
}

.c-product-detail__list {
  margin-top: var(--space-m);

  li {
    display: flex;
    align-items: center;
    font-size: var(--step--1);

    &::before {
      display: block;
      width: 3px;
      height: 3px;
      margin-right: var(--space-2xs);
      background: var(--color-gray-10);
      content: '';
    }
  }
}

.c-product-detail__right-bottom {
  grid-row: 3/4;

  @include media-breakpoint-up(sm) {
    grid-row: 2/3;
  }
}

.c-product-detail__list-icon {
  margin-top: calc(var(--space-l-xl) - var(--space-xs)); // - padding top of child
  border-bottom: 1px solid var(--color-gray-70);
}

.c-product-detail__list-download {
  margin-top: calc(var(--space-l-xl) - var(--space-xs)); // - padding top of child
  border-bottom: 1px solid var(--color-gray-70);

  .c-product-detail__list-icon + & {
    margin-top: 0;
  }
}

.c-product-detail__list-product {
  margin-top: var(--space-s-m);
  padding: var(--space-xs) 0;
}

.c-product-detail__list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-xs) 0;
  cursor: pointer;

  .is-open & .o-icon {
    transform: rotate(180deg);
  }
}

.c-product-detail__list-label {
  font-size: var(--step--1);
  font-weight: 700;
  text-transform: uppercase;
}

.c-product-detail__list-items {
  overflow: hidden;
  height: 0;

  .c-product-detail__list-icon & {
    display: flex;
    flex-wrap: wrap;
  }
}

.c-product-detail__icon-list-item {
  display: flex;
  align-items: center;
  width: 50%;

  &:last-child,
  &:nth-child(odd):nth-last-child(2) {
    margin-bottom: calc(var(--space-xs) * 1.5);
  }
}

.c-product-detail__icon-figure {
  position: relative;
  flex-shrink: 0;
  width: var(--space-l);
  height: var(--space-xl);
  margin-right: var(--space-xs);

  // background: pink;
}

.c-product-detail__icon-text {
  font-size: var(--step--1);
}

.c-product-detail__download-list-item {
  margin-top: var(--space-xs);

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
  }

  &:last-child {
    margin-bottom: calc(var(--space-xs) * 1.5);
  }
}

.c-products-detail__download-text {
  font-size: var(--step--1);
}

.c-product-detail__related-item--split {
  display: flex;
  gap: var(--space-s);

  @include media-breakpoint-up(sm) {
    flex-direction: column;
  }
}

.c-product-detail__product-item {
  display: flex;
  align-items: center;
  padding: var(--space-2xs-xs);
  text-decoration: none;
  background: rgba(#dedede, 0.2);
  border-radius: var(--border-radius);

  .c-product-detail__related-item--split & {
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}

.c-product-detail__product-figure {
  position: relative;
  overflow: hidden;
  width: var(--space-3xl);
  height: var(--space-3xl);
  margin-right: var(--space-xs);
  background: var(--color-white);
  border-radius: calc(var(--border-radius) * 0.65);

  .c-product-detail__related-item--split & {
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-right: 0;
      margin-bottom: var(--space-xs);
    }
  }
}

.c-product-detail__product-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.c-product-detail__product-text {
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;

  .c-product-detail__related-item--split & {
    @include media-breakpoint-down(sm) {
      font-size: var(--step--1);
    }
  }
}

.c-product-detail__product-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: var(--space-m);
  height: var(--space-m);
  margin-right: var(--space-3xs);
  margin-left: auto;
  border: 1px solid var(--color-black);
  border-radius: 50%;

  .o-icon {
    width: var(--space-xs);
    height: var(--space-xs);
  }
}

.c-product-detail__product-description {
  margin-top: var(--space-2xs);
  font-size: var(--step--1);
}

.c-product-detail__product-image {
  --spacer: var(--space-xs-s);

  position: absolute;
  top: var(--spacer);
  right: var(--spacer);
  bottom: var(--spacer);
  left: var(--spacer);
  background-size: contain;
}

.c-product-detail__icon-image {
  --spacer: 0;

  position: absolute;
  top: var(--spacer);
  right: var(--spacer);
  bottom: var(--spacer);
  left: var(--spacer);
  background-size: contain;
}
