.o-icon {
  display: inline-block;
  flex-shrink: 0;
  width: var(--step-0);
  height: var(--step-0);
  vertical-align: middle;
  font-size: 0;
  fill: none;
  stroke: currentcolor;
  stroke-width: $icon-stroke-width;
}

.o-icon__svg {
  width: 100%;
  height: 100%;
}

.o-icon--mail,
.o-icon--telephone,
.o-icon--play,
.o-icon--circle {
  width: var(--step-2);
  height: var(--step-2);
  stroke-width: 1.35px;
}

.o-icon--filter {
  width: var(--step-2);
  height: var(--step-2);
  stroke-width: 1.5px;
}

.o-icon--lens {
  scale: 0.95;
  stroke-width: 0.3px;
  fill: currentcolor;
}

.o-icon--burger {
  fill: currentcolor;
  stroke-width: 0.1px;
}

.o-icon--close {
  width: var(--step-2);
  height: var(--step-2);
  stroke-width: 1.5px;
}

.o-icon--close-small {
  width: var(--step--2);
  height: var(--step--2);
}

.o-icon--youtube,
.o-icon--linkedin,
.o-icon--instagram,
.o-icon--facebook,
.o-icon--pinterest,
.o-icon--houzz {
  width: var(--step--1);
  height: var(--step--1);
  fill: currentcolor;
  stroke: none;
}
