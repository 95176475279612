.c-project-list {
  a {
    text-decoration: none;
  }
}

.c-project-list__grid {
  --gutter-y: var(--space-3xl-6xl);
}

.c-project-list__title {
  font-size: var(--step-3);

  @include media-breakpoint-up(md) {
    font-size: var(--step-5);
  }

  @include media-breakpoint-up(lg) {
    font-size: var(--step-6);
  }
}

.c-project-list__item {
  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  &:nth-child(3n) {
    @include media-breakpoint-up(sm) {
      --column-span: 10;
      --column-start: 2;
    }
  }
}

.c-project-list__content {
  margin-top: var(--space-m);
  margin-left: var(--space-2xs);
}

.c-project-list__content-title {
  @include text-shadow(var(--color-gray-10));

  display: inline;
  font-size: var(--step-3);

  @include media-breakpoint-up(sm) {
    font-size: var(--step-4);
  }

  .can-hover & {
    background-image: linear-gradient(currentcolor, currentcolor);
    background-position: 100% 95%;
    background-repeat: no-repeat;
    background-size: 0 2px;
    transition: background-size 0.75s cubic-bezier('easeOutQuint');
  }

  .can-hover a:hover & {
    background-position: 0% 95%;
    background-size: 100% 2px;
  }
}

.c-project-list__content-subtitle {
  margin-top: var(--space-xs);
  font-size: var(--step--1);
  text-transform: uppercase;
  opacity: var(--opacity-inactive);
}

.c-project-list__figure {
  position: relative;
  overflow: hidden;
  padding-bottom: calc(3 / 4) * 100%;
  background: var(--color-placeholder);
  border-radius: var(--border-radius);

  @include media-breakpoint-up(sm) {
    .c-project-list__item:nth-child(3n) & {
      padding-bottom: calc(2 / 4) * 100%;
    }
  }
}

.c-project-list__image {
  @include image-bg;

  transition: opacity 0.3s, transform 2s cubic-bezier('easeOutQuint') !important;
  transform: scale(1.001);

  .can-hover a:hover & {
    transform: scale(1.025);
  }
}
