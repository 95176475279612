.c-site-menu {
  position: fixed;
  z-index: $z-index-menu;
  top: var(--site-header-height);
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;

  .show-menu & {
    pointer-events: auto;
  }

  a {
    display: inline-block;
    text-decoration: none;

    .can-hover & {
      transition: color 0.2s;

      &:hover {
        color: var(--color-mariner);
        opacity: 1;
      }
    }
  }

  .is-active a {
    color: var(--color-gray-30);
  }

  [style*='--index'] {
    opacity: 0;
    transform: translateY(var(--space-m));

    .show-menu & {
      opacity: 1;
      transition: opacity 0.8s, transform 1s;
      transition-delay: calc(0.2s + var(--index) * 0.03s);
      transition-timing-function: cubic-bezier('easeOutQuart');
      transform: none;
    }
  }
}

.c-site-menu__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--color-gray-10);
  opacity: 0;
  transition: opacity 0.3s;

  .show-menu & {
    opacity: 1;
  }
}

.c-site-menu__main-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .show-menu & {
    overflow: auto;
  }
}

.c-site-menu__grid {
  // --gutter-y: ;
  grid-template-rows: auto 1fr;

  // align-items: flex-start;
  min-height: 100%;
  padding-top: var(--space-2xl-3xl);
  padding-bottom: var(--space-l);

  @include media-breakpoint-up(sm) {
    --gutter-y: var(--space-xl);
  }

  @include media-breakpoint-up(md) {
    grid-template-rows: 1fr;
  }
}

.c-site-menu__nav-primary {
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.02em;

  @include media-breakpoint-up(md) {
    --column-span: 8;
  }

  li {
    font-size: var(--step-4);
    opacity: var(--opacity-active);

    @include media-breakpoint-up(sm) {
      font-size: var(--step-6);
    }
  }
}

.c-site-menu__product-search {
  display: inline-block;
  margin-top: calc(var(--gutter-y) * 0.5);

  @include media-breakpoint-up(md) {
    font-size: var(--step-1);
  }
}

.c-site-menu__nav-secondary {
  // --column-span: 7;
  // margin-top: var(--space-m);
  line-height: 2;

  @include media-breakpoint-up(md) {
    --column-span: 4;

    font-size: var(--step-1);

    // line-height: 1.75;
  }
}

.c-site-menu__nav-button {
  margin-top: var(--gutter-y);
}

.c-site-menu__nav-lang {
  align-self: center;

  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  @include media-breakpoint-up(md) {
    font-size: var(--step--1);
  }

  .is-active a {
    text-decoration: underline;
  }

  // --column-span: 7;
  // margin-top: var(--space-3xl);
}

.c-site-menu__nav-social {
  // @include media-breakpoint-down(sm) {
  //   --column-span: 5;
  //   --column-start: 8;

  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: flex-end;
  //   grid-row: 2/4;
  // }
}

.c-site-menu__nav-social {
  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  li {
    font-size: 0;

    &:not(:first-child) {
      margin-left: var(--space-xs);
    }

    a {
      @extend %icon-social;
    }
  }
}
