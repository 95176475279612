html.cke_panel_container {
  body {
    color: #000;
  }

  a:hover {
    text-decoration: none;
  }
}

.cke_source  {
  color: #000 !important;
}

body.cke_editable {
  margin: var(--step-0);
}
