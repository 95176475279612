.c-finishes {
  position: relative;
  padding-top: 0 !important; // for styleguide preview

  a {
    text-decoration: none;
  }
}

.c-finishes__headline {
  display: flex;
  align-items: center;
  padding-bottom: var(--space-2xs);
  border-bottom: 1px solid var(--color-gray-70);

  .c-finishes__figure {
    display: none;
  }

  .o-icon--chevron {
    display: none;
    margin-left: auto;

    .show-items & {
      transform: rotate(180deg);
    }
  }

  .o-icon--arrow-small {
    display: block;
    margin: 0 calc(var(--space-2xs) * 0.5);
  }
}

.c-finishes__label {
  display: block;
  font-size: var(--step--1);
  font-weight: 700;
  text-transform: uppercase;
}

.c-finishes__active {
  display: block;
  align-items: center;
  font-size: var(--step--1);
  font-weight: 700;
  text-transform: none;
}

.c-finishes__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(-1 * var(--space-m));

  .c-product-detail & {
    margin-left: calc(-1 * var(--space-s));
  }
}

.c-finishes__link-wrapper {
  position: relative;
  margin-top: var(--space-s);
  margin-left: var(--space-m);

  .c-product-detail & {
    margin-left: var(--space-s);
  }

  &.is-active {
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 125%;
      height: 125%;
      border: 1px solid var(--color-gray-10);
      border-radius: 50%;
      content: '';
      transform: translate(-50%, -50%);
    }
  }
}

.c-finishes__link {
  position: relative;

  * {
    pointer-events: none;
  }
}

.c-finishes__type {
  .c-finishes__list & {
    position: absolute;
    left: 50%;
    display: none;
    padding: 0.5em 0.75em;
    color: var(--color-white);
    font-size: var(--step--2);
    text-align: center;
    white-space: nowrap;
    background: var(--color-gray-30);
    border-radius: 4px;
    opacity: 0;
    transform: translate(-50%, var(--space-xs));

    &::before {
      position: absolute;
      top: 0;
      left: 50%;
      width: 10px;
      height: 10px;
      background: var(--color-gray-30);
      transform: translate(-50%, -50%) rotate(45deg);
      content: '';
    }
  }

  .c-finishes__link:hover & {
    .can-hover & {
      z-index: 1;
      display: block;
      line-height: 1;
      opacity: 1;
    }
  }

  .c-finishes__active & {
    display: block;
    font-size: var(--step--1);
    font-weight: 700;
  }
}

.c-finishes__figure {
  position: relative;
  display: block;
  overflow: hidden;
  width: var(--space-m);
  height: var(--space-m);
  border-radius: 50%;

  .c-product-detail & {
    width: var(--space-s);
    height: var(--space-s);
  }
}

.c-finishes__image {
  @include image-bg;
}
